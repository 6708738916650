"use client"
import {SolutionComponent} from "@/components/solutions/SolutionComponent";
import HeaderContainer from "@/components/header/HeaderContainer";
import HeroComponent from "@/components/hero/HeroComponent";
import FeatureComponent from "@/components/feature/FeatureComponent";
import {FirstGlanceComponent} from "@/components/first-glance/FirstGlanceComponent";
import {HepsiburadaComponent} from "@/components/hepsiburada/HepsiburadaComponent";
import {TryNowComponent} from "@/components/try-now/TryNowComponent";
import {IndustryComponent} from "@/components/industry/IndustryComponent";
import ContactUsComponent from "@/components/contact-us/ContactUsComponent";
import FooterComponent from "@/components/footer/FooterComponent";
import {BrandCommentsContainer} from "@/components/brand-comments/BrandCommentsContainer";
import {brandName} from "@/components/hero/HeroMarqueeWall";
import {useTranslations} from "next-intl";

export default function Homepage() {
	const t = useTranslations('');

	const comments = [
		{
			comment: t("homepage.comments.akode.comment"),
			author: t("homepage.comments.akode.author"),
			authorDescription: t("homepage.comments.akode.authorDescription"),
			authorImage: "/brands/avatar/avatar.svg",
			brandImage: "/brands/akode.png"
		},
		{
			comment: t("homepage.comments.hepsiburada.comment"),
			author: t("homepage.comments.hepsiburada.author"),
			authorDescription: t("homepage.comments.hepsiburada.authorDescription"),
			authorImage: "/brands/avatar/avatar.svg",
			brandImage: "/brands/hepsiburada.png"
		},
		{
			comment: t("homepage.comments.anadolubank.comment"),
			author: t("homepage.comments.anadolubank.author"),
			authorDescription: t("homepage.comments.anadolubank.authorDescription"),
			authorImage: "/brands/avatar/avatar.svg",
			brandImage: "/brands/anadolubank.png"
		},
		{
			comment: t("homepage.comments.unitedpayment.comment"),
			author: t("homepage.comments.unitedpayment.author"),
			authorDescription: t("homepage.comments.unitedpayment.authorDescription"),
			authorImage: "/brands/avatar/avatar.svg",
			brandImage: "/brands/unitedpayment.png",
			brandClassName: "w-[70px]"
		},
		{
			comment: t("homepage.comments.pazarama.comment"),
			author: t("homepage.comments.pazarama.author"),
			authorDescription: t("homepage.comments.pazarama.authorDescription"),
			authorImage: "/brands/avatar/avatar.svg",
			brandImage: "/brands/pazarama.png",
			className: "text-xs xl:text-xl"
		}
	];

	const brands: brandName[] = ["akode", "anadolubank", "misyon", "dgpays", "flogroup", "hepsiburada", "hepsipay", "hepsifinans", "moka", "nurolbank", "pazarama", "unitedpayment", "moneypay", "papara", "sipay", "papel", "moneytolia", "colendi", "goldenglobal", "hayatfinans", "platformodeme", "bpn"];

	return (
		<div className={"flex flex-col"}>
			<HeaderContainer/>
			<HeroComponent title={t("homepage.hero.title")}
			               subtitle={t("homepage.hero.subtitle")}
			               description={t("homepage.hero.description")}
			               buttonTitle={t("homepage.hero.buttonTitle")} buttonHref={"#contact-us"} imageName={"homepage"}
			               brands={brands}/>
			<HepsiburadaComponent/>
			<SolutionComponent/>
			<BrandCommentsContainer comments={comments}/>
			<IndustryComponent/>
			<FirstGlanceComponent/>
			<FeatureComponent title={t("features.quickOnboarding.title")} subtitle={t("features.quickOnboarding.subtitle")}
			                  image={"/features/Home_Page_Quick_Onboarding.png"}
			                  description={t("features.quickOnboarding.description")}
			                  reverse
			/>
			<TryNowComponent title={t("tryNow.title")}
			                 description={t("tryNow.description")}
			                 buttonHref={"#contact-us"}
			                 buttonTitle={t("tryNow.buttonTitle")}
			/>
			<ContactUsComponent/>
			<FooterComponent/>
		</div>
	);
}
